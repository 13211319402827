// CUR COMPONENT
import React, { useState, useEffect } from 'react'
import { IAuth } from 'types/auth'
import { connect } from 'react-redux'
import { loginUser } from 'state/actions/auth'
import { createToken } from 'fetch'

// COMPONENTS
import Input from 'components/Input'
import Button from 'components/Button'
import FormWrapper from 'components/Form/FormWrapper'
import Logo from 'components/Logo'



type Props = {
  loginUser(user: IAuth): void
}

const AUTH:React.FC<Props> = ({ loginUser }) => {
  const [login, setLogin] = useState('')
  const [loginValid, setLoginValid] = useState(true)
  const [loginStatus, setLoginStatus] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [passwordStatus, setPasswordStatus] = useState(false)

  useEffect(() => {
    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).LOGIN_PAGE_TITLE }`
  }, [])

  const loginHandler = (e) => {
    setLogin(e.target.value)
    setLoginValid(true)
    setLoginStatus(e.target.validity.valid)
  }
  const passwordHandler = (e) => {
    setPassword(e.target.value)
    setPasswordValid(true)
    setPasswordStatus(e.target.validity.valid)
  }
  const submitForm = (e) => {
    e.preventDefault()

    const data = {
      phone: login,
      password: password,
      device_name: navigator.userAgent
    }

    createToken(data)
    .then(res => {
      if (res.token) {
        loginUser(res)
      } else {
        Object.keys(res.errors).forEach(i => {
          switch (i) {
            case 'phone':
              setLoginValid(false)  
            case 'password':
              setPasswordValid(false)
          }
        })
      }
    })
  }

  return (
    <div className="main auth-page">
      <div className="main__logo">
        <Logo />
      </div>
      <form className="form" onSubmit={submitForm}>
        <FormWrapper>
          <Input 
            label="логин" 
            type="text" 
            isRequired={true}
            valid={loginValid}
            onChange={loginHandler} />
        </FormWrapper>
        <FormWrapper>
          <Input 
            label="пароль" 
            type="password" 
            isRequired={true}
            valid={passwordValid}
            onChange={passwordHandler} />
        </FormWrapper>
        <FormWrapper cpadding={30}>
          <Button disabled={!(loginStatus && passwordStatus)}>Войти</Button>
        </FormWrapper>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  loginUser: (user: IAuth) => dispatch(loginUser(user))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AUTH) 