import { getCookie } from 'cookieS'

const url = (window as any).APIURL

const config = (method = "GET", body?) => {
  const cookie = getCookie("_token")
  const token = cookie ? JSON.parse(cookie).token : ""
  return {
    method: method,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Accept": "application/json",
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`
    },
    body: body
  }
}

const revokeToken = async () => {
  const response = await fetch(`${url}/auth/token/revoke`, config())
  const data = await response.json()

  return data
}
const createToken = async (reqData) => {
  const response = await fetch(`${url}/auth/token`, config('POST', JSON.stringify(reqData)))
  const data = await response.json()

  return data
}
const checkAuth = async () => {
  const response = await fetch(`${url}/users/me`, config())
  const data = await response.json()

  if (!response.ok) throw "UNAUTH"

  return data
}

const getMembers = async (link = '') => {
  const response = await fetch(`${url}/member/list${link}`, config())
  const data = await response.json()
  
  if (!response.ok) throw Error('')

  return data
}
const getMember = async (id) => {
  const response = await fetch(`${url}/member/item/${id}`, config())
  const data = await response.json()

  return data
}
const passMember = async (id) => {
  const response = await fetch(`${url}/member/check-in`, config('POST', JSON.stringify({uuid: id})))
  const data = await response.json()

  if (!response.ok) throw {...data, status: response.status}
  
  return data
}
const checkoutMember = async (id) => {
  const response = await fetch(`${url}/member/check-out`, config('POST', JSON.stringify({uuid: id})))
  const data = await response.json()

  if (!response.ok) throw {...data, status: response.status}

  return data
}
const passMemberEntrace = async (id) => {
  const response = await fetch(`${url}/member/check-in/entrance`, config('POST', JSON.stringify({uuid: id})))
  const data = await response.json()

  if (!response.ok) throw {...data, status: response.status}
  
  return data
}
const checkoutMemberEntrace = async (id) => {
  const response = await fetch(`${url}/member/check-out/entrance`, config('POST', JSON.stringify({uuid: id})))
  const data = await response.json()

  if (!response.ok) throw {...data, status: response.status}

  return data
}
const checkinDelete = async (id) => {
  const response = await fetch(`${url}/member/check-in`, config('DELETE', JSON.stringify({uuid: id})))

  if (!response.ok) throw {status: response.status}

  return 'ok'
}

const getMemberStats = async () => {
  const response = await fetch(`${url}/member/stats`, config())
  const data = await response.json()

  if (!response.ok) throw {status: response.status}

  return data
}

export { createToken, getMembers, getMember, passMember, checkoutMember, revokeToken, checkAuth, checkinDelete, getMemberStats, passMemberEntrace, checkoutMemberEntrace }