import { TAuth } from 'types/auth'  
import { getCookie, setCookie, deleteCookie } from 'cookieS'

const initUser = () => {
  const user = getCookie("_token")
  
  return user ? user : null
}

const auth = (state: TAuth = initUser() as TAuth, action: any) => {
  switch (action.type) {
    case 'LOGIN_USER':
      console.log(action.user)
      const date = new Date()
      setCookie("_token", action.user, {expires: date.setDate(date.getDate() + 1)})
      return action.user
    case 'LOGOUT_USER':
      deleteCookie("_token")
      return null
    default:
      return state
  }
}




export default auth