// CUR COMPONENT
import React from 'react'
import './style.scss'

// ICONS
import { ReactComponent as MailIcon } from './MailIcon.svg'
import { ReactComponent as PhoneIcon } from './PhoneIcon.svg'



type Props = {
  type: string
}

const Contact:React.FC<Props> = ({ type }) => {

  switch (type) {
    case 'phone':
      return <PhoneIcon />
    case 'mail':
      return <MailIcon />
    default:
      return <></>
  }
}

export default Contact