// CUR COMPONENT
import React from 'react'
import './style.scss'



type Props = {
  type?: string,
  disabled?: boolean,
  onClick?: any
}

const Button:React.FC<Props> = ({ type, disabled = false, children, onClick }) => {

  const classes = ["button"]
  if (type) classes.push(`button_${type}`)

  return (
    <button 
      className={classes.join(" ")}
      disabled={disabled}
      onClick={() => {
        if (onClick) onClick()
      }} >
        { children }
    </button>
  )
}

export default Button 