// CUR COMPONENT
import React from 'react'
import './style.scss'



type Props = {
  cpadding?: number
}

const FormWrapper:React.FC<Props> = ({ children, cpadding }) => {

  return (
    <div 
      className="form__wrapper"
      style={{marginTop: cpadding}}>
      { children }
    </div>
  )
}

export default FormWrapper 