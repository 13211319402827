// CUR COMPONENT
import React from 'react'
import './style.scss'

// COMPONENTS
import LogoSvg from './logo.svg'



const Logo:React.FC = () => {

  return (
    <div className="logo">
      <img src={ (window as any).LOGO || LogoSvg } alt=""/>
    </div>
  )
}

export default Logo 