// CUR COMPONENT
import React from 'react'
import './style.scss'

// COMPONENTS
import Contact from 'components/Contact'



type Props = {
  member: any
}

const MemberCard:React.FC<Props> = ({ member }) => {

  return (
    <div className="member-card">
      <h1 className="member-card__title">{ `${member.name}` }</h1>
      <p>Зал: { member.hall }</p>
      <p>Место: { member.place }</p>
      <p>Ряд: { member.row }</p>
      
      <p>{ member.location }</p>
      <ul className="member-card__contacts">
        {member.email && (
          <li className="member-card__contact">
            <span className="member-card__icon"><Contact type={'mail'} /></span> 
            <span>{member.email}</span>
          </li>
        )}
        {member.phone && (
          <li className="member-card__contact">
            <span className="member-card__icon"><Contact type={'phone'} /></span> 
            <span>{member.phone}</span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default MemberCard