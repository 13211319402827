//CUR COMPONENT
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from 'state'
import './index.scss';

//PAGES
import APP from 'pages/APP'

const store = createStore(rootReducer)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <div className="app">
          <style>
            {`
              .app .button:not(:disabled) {
                color: ${(window as any).COLOR_PRIMARY};
                border-color: ${(window as any).COLOR_PRIMARY};
              }
              .app .button:not(:disabled):hover,
              .app .button.button_hovered {
                color: #000;
                background-color: ${(window as any).COLOR_PRIMARY};
              }
              .app .member.member_active {
                background-color: ${(window as any).COLOR_PRIMARY}50;
              }
              .app .member.member_active:hover {
                background-color: ${(window as any).COLOR_PRIMARY}80;
              }
              .app .member.member_error {
                background-color: ${(window as any).COLOR_SECONDARY}50;
              }
              .app .member.member_error:hover {
                background-color: ${(window as any).COLOR_SECONDARY}80;
              }
              .app .card.card_success {
                background-color: ${(window as any).COLOR_CARD_SUCCESS};
              }
              .app .card.card_warning {
                background-color: ${(window as any).COLOR_CARD_WARNING};
              }
              .app .card.card_error {
                background-color: ${(window as any).COLOR_CARD_ERROR};
              }
              .app .scanner-page .scanner-page__sel-type.scanner-page__sel-type_active {
                background-color: ${(window as any).COLOR_PRIMARY};
              }
            `}
          </style>
          <APP />
        </div>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);