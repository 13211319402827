const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const setCookie = (name, value, options = {} as any) => {

  options = {
    path: '/',
    ...options
  };
  
  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey]
    if (optionValue !== true) updatedCookie += "=" + optionValue
  }

  document.cookie = updatedCookie
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}


export { getCookie, setCookie, deleteCookie }