// CUR COMPONENT
import React, { useEffect, useState } from 'react'
import Reader from 'react-qr-reader'
import { passMember, passMemberEntrace, checkoutMember, checkoutMemberEntrace, checkinDelete } from 'fetch'

// COMPONENTS
import Card from 'components/Card'
import Header from 'components/Header'
import Button from 'components/Button'



const enterBody = data => ({
  title: 'Вход',
  type: 'success',
  body: <>
    <p>Последний вход {new Date(data.check_in_at).toLocaleString()}</p>
    <p>{data.user}</p>
    { data.is_free_seat ? (
      <>
        <p>Свободная рассадка</p>
      </>
    ) : (
      <>
        <p>Зал: {data.hall}</p>
        <p>Ряд: {data.row}</p>
        <p>Место: {data.place}</p>
        <p>{data.location}</p>
      </>
    )}
    <Button type="hovered" onClick={() => checkinDelete(data.uuid)}>
      Выпустить
    </Button>
  </>
})
const exitBody = data => ({
  title: 'Выход',
  type: 'error',
  body: <>
    <p>{data.name}</p>
    <p>Вышел</p>
  </>
})
const warningBody = () => ({
  title: 'Ошибка',
  type: '',
  body: <>
    <p>Такого QR кода не существует в базе приглашений</p>
  </>
})
const errorBody = (message = 'Непредвиденная ошибка') => ({
  title: 'Ошибка',
  type: 'warning',
  body: <>
    <p>{ message }</p>
  </>
})
const SCANNER: React.FC = () => {
  const [res, setRes] = useState<string>('')
  const [hallType, setHallType] = useState<boolean>(true)
  const [scanerType, setScanerType] = useState<string>('ENTER')
  const [card, setCard] = useState<any>(null)
  const [showCard, setShowCard] = useState(false)

  useEffect(() => {
    document.title = `${(window as any).PROJECT_NAME} | ${(window as any).QR_PAGE_TITLE}`
  }, [])

  const handleError = e => {
    alert(e)
  }
  const handleScan = (res: string) => {
    if (res && card === null) {
      setRes(res)
    }
  }
  const closeCard = () => {
    setRes('')
    setCard(null)
    setShowCard(false)
  }

  useEffect(() => {
    let isSubscribed = true;

    if (res) {
      let serverLink;
      if (hallType)
        serverLink = scanerType === 'ENTER' ? passMemberEntrace(res) : checkoutMemberEntrace(res)
      else
        serverLink = scanerType === 'ENTER' ? passMember(res) : checkoutMember(res)

      serverLink
        .then(({ data }) => {
          if (isSubscribed) {
            if (scanerType === 'ENTER') {
              setCard(enterBody(data));
            } else {
              setCard(exitBody(data))
            }
          }
        })
        .catch(er => {
          const data = er.data;

          if (isSubscribed) {
            switch (er.status) {
              case 404:
                setCard(warningBody())
                break  
              case 409:
                setCard(errorBody('Гость уже внутри'))
                break
              case 422:
                setCard(errorBody(data.message))
                break
              case 500:
                setCard(errorBody())
                break
            }
          }
        })
        .finally(() => {
          if (isSubscribed) {
            setShowCard(true)
            setRes('')
          }
        })
    }

    return () => {
      isSubscribed = false;
    }
  }, [res])

  return (
    <div className="main scanner-page">
      <Header title="Сканер QR" />
      <div className="scanner">
        {card && (
          <Card
            title={card.title}
            type={card.type}
            closeCard={closeCard}
            className={showCard ? 'card_open' : 'card_close'}
          >
            { card.body}
          </Card>
        )}
        <Reader
          className="QR_SCANNER"
          style={{ width: '100%', height: '100%' }}
          onScan={handleScan}
          onError={handleError}
          facingMode={'environment'}
          delay={500}
          showViewFinder={false}
          legacyMode={false}
        />
        <div className="scanner-page__sel-types">
          <p>Кинотеатр</p>
          <div className="scanner-page__c">
            <div
              onClick={() => { setHallType(true); setScanerType('ENTER') }}
              className={`scanner-page__sel-type ${hallType && scanerType === 'ENTER' ? 'scanner-page__sel-type_active' : ''}`}
            >
              Впустить
            </div>
            <div
              onClick={() => { setHallType(true); setScanerType('EXIT') }}
              className={`scanner-page__sel-type ${hallType && scanerType === 'EXIT' ? 'scanner-page__sel-type_active' : ''}`}
            >
              Выпустить
            </div>
          </div>
          <p>Зал</p>
          <div className="scanner-page__c">
            <div
              onClick={() => { setHallType(false); setScanerType('ENTER') }}
              className={`scanner-page__sel-type ${!hallType && scanerType === 'ENTER' ? 'scanner-page__sel-type_active' : ''}`}
            >
              Впустить
            </div>
            <div
              onClick={() => { setHallType(false); setScanerType('EXIT') }}
              className={`scanner-page__sel-type ${!hallType && scanerType === 'EXIT' ? 'scanner-page__sel-type_active' : ''}`}
            >
              Выпустить
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SCANNER
