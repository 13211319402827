// CUR COMPONENT
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getMembers } from 'fetch'

// COMPONENTS
import Header from 'components/Header'
import Members from 'components/Members'
import Search from 'components/Search'



const MEMBERS:React.FC = () => {
  const [ members, setMembers ] = useState<any>([])
  const [ links, setLinks ] = useState<any>()
  const [ filter, setFilter ] = useState<string>("")
  const firstPage = useRef<any>(null)
  const lastPage = useRef<any>(null)
  
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).USERS_PAGE_TITLE }`
  }, [])
  useEffect(() => {
    let isSubscribed = true
    getMembers(location.search)
    .then((res) => {
      if (isSubscribed) {
        const prevPage = links
        const curPage = res.meta.current_page

        setLinks({...res.links, page: res.meta.current_page})
        if (curPage < prevPage) setMembers(prev => [...res.data, ...prev])
        else setMembers(prev => [...prev, ...res.data])
      }
    })
    .catch(() => {
      setMembers([])
    })

    return () => {isSubscribed = false}
  }, [ location, links ])
  useEffect(() => {
    if (links) {
      if (firstPage.current === null || links.page < firstPage?.current) firstPage.current = links.page
      if (lastPage.current === null || links.page > lastPage?.current) lastPage.current = links.page
    }

    document.addEventListener('scroll', listener)

    return () => {
      document.removeEventListener('scroll', listener, false)
    } 
  }, [ links ])

  const listener = () => {
    const scrollTop = window.pageYOffset
    if (links.prev && scrollTop <= 50) {
      history.push(`?page=${firstPage.current - 1}`) 
      document.removeEventListener('scroll', listener, false)
    }
    if (links.next && scrollTop + window.innerHeight >= document.body.scrollHeight - 50) {      
      history.push(`?page=${lastPage.current + 1}`)
      document.removeEventListener('scroll', listener, false)
    }
  }

  const changeHandler = (value) => {
    setFilter(value)
  }
  

  return (
    <div className="main members-page" >
      <Header title="Список пользователей" />
      
      <div className="members-page__search">
        <Search onChange={changeHandler} />
      </div>

      {members.length === 0 ? (
        <p className="nothing">Ничего не найдено</p>
      ) : ( 
        <Members links={links} members={members} filter={filter} />
      )}
    </div>
  )
}

export default MEMBERS