// CUR COMPONENT
import React from 'react'

// COMPONENTS



type Props = {
  stats?: any
}

const Stats:React.FC<Props> = ({ stats }) => {
  return (
    <div className="stats">
      {stats && (
        <>
          <p>Всего: { stats.total }</p>
          <p>Вошло: { stats.checkIn }</p>
        </>
      )}
    </div>
  )
}

export default Stats