// CUR COMPONENT
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { membersRoute, scannerRoute, logoutRoute } from 'routes'
import { getMemberStats } from 'fetch'

// COMPONENTS
import Logo from 'components/Logo'
import Button from 'components/Button'
import FormWrapper from 'components/Form/FormWrapper'
import Stats from 'components/Stats'



const MAIN:React.FC = () => {
  const [stats, setStats] = useState<any>()
  useEffect(() => {
    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).MAIN_PAGE_TITLE }`

    getMemberStats()
    .then((res) => {
      setStats(res)
    })
    .catch(() => {

    })
  }, [])

  return (
    <div className="main menu-page">
      <div className="main__logo">
        <Logo />
      </div>
      <div className="menu-page__menu">
        <FormWrapper>
          <Link to={membersRoute.get()}>
            <Button>Список пользователей</Button>
          </Link>
        </FormWrapper>
        <FormWrapper>
          <Link to={scannerRoute.get()}>
            <Button>QR Сканер</Button>
          </Link>
        </FormWrapper>
        <FormWrapper>
          <Link to={logoutRoute.get()}>
            <Button>Выйти</Button>
          </Link>
        </FormWrapper>
        <FormWrapper>
          <Stats stats={stats} />
        </FormWrapper>
      </div>
    </div>
  )
}

export default MAIN