// CUR COMPONENT
import React, { useEffect } from 'react'



const ANY:React.FC = () => {
  useEffect(() => {
    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).ERROR_PAGE_TITLE }`
  }, [])

  return (
    <div>
      <h1 style={{ textAlign: 'center', lineHeight: '100vh' }}>404</h1>
    </div>
  )
}

export default ANY