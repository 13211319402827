// CUR COMPONENT
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getMember } from 'fetch'

// COMPONENTS
import Header from 'components/Header'
import MemberCard from 'components/MemberCard'
import MemberActions from 'components/MemberActions'



const MEMBER:React.FC = () => {
  const [ member, setMember ] = useState<any>(null)
  const {id} = useParams()

  useEffect(() => {
    let isSubscribed = true

    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).USER_PAGE_TITLE }`
    
    getMember(id)
    .then((res) => {
      if (isSubscribed) setMember(res.data)
    })

    return () => {isSubscribed = false}
  }, [])

  return (
    <div className="main member-page">
      <Header />
      
      {member ? (
        <>
          <MemberCard member={member} />
          <MemberActions 
            member={member.uuid} 
            setMember={setMember} 
            checkIn={member.check_in} 
            checkOut={member.check_out} 
            checkInEntrace={member.check_in_entrace}
            checkOutEntrace={member.check_out_entrace}
          />
        </>
      ) : (
        <p className="nothing">Ничего не найдено</p>
      )}
    </div>
  )
}

export default MEMBER