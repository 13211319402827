// CUR COMPONENT
import React, { useEffect, useState, useRef } from 'react'
import './style.scss'



type Props = {
  label: string,
  type?: string,
  valid?: boolean,
  isRequired?: boolean,
  onChange?: (data?: any) => any
}

const Input:React.FC<Props> = ({ label, type = 'text', valid = true, isRequired = false, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [ classes, setClasses ] = useState(['input'])

  useEffect(() => {
    if (!valid) setClasses((prev) => [...prev.filter(i => i !== 'input_valid'), 'input_invalid'])
  }, [valid])

  const focusInput = () => {
    setClasses(prev => {
      return [...prev, 'input_focus']
    })
  }
  const blurInput = () => {
    setClasses(prev => {
      return prev.filter(i => i !== 'input_focus')
    })
  }
  const changeInput = (e) => {

    if (isRequired) {
      if (!classes.includes('input_valid') && inputRef.current?.validity.valid) {
        setClasses(prev => { return [...prev.filter(i => i !== 'input_invalid'), 'input_valid'] })
      } 
      if (!classes.includes('input_invalid') && !inputRef.current?.validity.valid) {
        setClasses(prev => { return [...prev.filter(i => i !== 'input_valid'), 'input_invalid'] })
      }
    }
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <label 
      className={classes.join(" ")}>
        <input 
          type={type} 
          ref={inputRef}
          onBlur={blurInput}
          onFocus={focusInput}
          required={isRequired}
          onChange={changeInput}
          className='input__input' 
          placeholder=" "
        />
        <span 
          className='input__label'>
            {label}
        </span>
    </label>
  )
}

export default Input 