// CUR COMPONENT
import React from 'react'
import './style.scss'

// COMPONENTS
import { ReactComponent as Arrow } from './Arrow.svg'



type Props = {
  name: string,
  isEnter?: boolean,
  onClick?: (data?: any) => any
  type?: string,
  disabled?: boolean
}

const Member:React.FC<Props> = ({ disabled, onClick, name, type }) => {
  const classes = ["member"]
  
  if (type === 'success') classes.push("member_active")
  if (type === 'error') classes.push("member_error")
  if (disabled) classes.push("member_disabled")

  const clickHandler = (e) => {
    if (onClick) onClick(e)
  }

  return (
    <div className={classes.join(" ")} onClick={(e) => clickHandler(e)}>
      <span>{name}</span>
      <span className="member__arrow"><Arrow /></span>
    </div>
  )
}

export default Member