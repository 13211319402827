// CUR COMPONENT
import React from 'react'
import './style.scss'

// COMPONENTS
import { ReactComponent as SearchIcon } from './SearchIcon.svg'



type Props = {
  onChange?: (data?: any) => any
}

const Search:React.FC<Props> = ({ onChange }) => {
  
  const changeHandler = (e) => {

    if (onChange) onChange(e.target.value)
  }

  return (
    <div className="search">
      <SearchIcon />
      <input type="text" className="search__input" placeholder="Поиск..." onChange={changeHandler} />
    </div>
  )
}

export default Search