//CUR COMPONENT
import React from 'react'
import './style.scss'
import { Link, useHistory } from 'react-router-dom'
import { back } from 'routes'

// ICONS
import { ReactComponent as Arrow } from './Arrow.svg'



type Props = {
  title?: string
}

const Header:React.FC<Props> = ({ title = '' }) => {
  const history = useHistory()

  const onClick = () => {
    if (history.length === 1) {
      history.push("")
    }
    history.goBack()
  }

  return (
    <header className="header">
      <div className="header-wrapper">
        <div className="header__arrow" onClick={onClick}><Arrow /></div>
        <h2 className="header__title">{ title }</h2>
      </div>
    </header>
  )
}

export default Header