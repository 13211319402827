// CUR COMPONENT
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { revokeToken } from 'fetch'
import { logoutUser } from 'state/actions/auth'



type Props = {
  logoutUser(): void
}

const LOGOUT:React.FC<Props> = ({ logoutUser }) => {
  
  useEffect(() => {
    document.title = `${ (window as any).PROJECT_NAME } | ${ (window as any).LOGOUT_PAGE_TITLE }`
    
    revokeToken()
    .then(res => {
      logoutUser()
    })
  }, [])

  return (
    <>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LOGOUT)