// CUR COMPONENT
import React from 'react'
import './style.scss'
import {
  passMember as pass,
  checkoutMember as checkout,
  passMemberEntrace as passEntrace,
  checkoutMemberEntrace as checkoutEntrace
} from 'fetch'

// COMPONENTS
import Member from 'components/Member'



type Props = {
  checkIn: boolean,
  checkOut: boolean,
  checkInEntrace: boolean,
  checkOutEntrace: boolean,
  member: string,
  setMember(data: any): void
}

const MemberCard:React.FC<Props> = ({ checkIn, setMember, checkOut, member, checkInEntrace, checkOutEntrace }) => {

  const passMember = () => {
    pass(member)
    .then(res => {    
      setMember(res.data)
    })
  }
  const escortMember = () => {
    checkout(member)
    .then(res => {
      setMember(res.data)
    })
  }
  const passMemberEntrace = () => {
    passEntrace(member)
    .then(res => {
      setMember(res.data)
    })
  }
  const checkoutMemberEntrace = () => {
    checkoutEntrace(member)
    .then(res => {
      setMember(res.data)
    })
  }

  return (
    <div className="member-actions">
      <div className="member-actions__status">Статус: { checkIn ? checkOut ? 'вышел' : 'внутри' : 'не внутри' }</div>
      
      <p style={{ marginTop: '2rem' }}>Кинотеатр</p>
      <Member name="ПРОПУСТИТЬ" onClick={passMemberEntrace} type="success" disabled={!checkOut && checkIn} />
      {(!checkOut && checkIn) && <Member name="ВЫПУСТИТЬ" onClick={checkoutMemberEntrace} type="error" />}
      <p style={{ marginTop: '2rem' }}>Зал</p>
      <Member name="ПРОПУСТИТЬ" onClick={passMember} type="success" disabled={!checkOutEntrace && checkInEntrace} />
      {(!checkOut && checkIn) && <Member name="ВЫПУСТИТЬ" onClick={escortMember} type="error" />}
    </div>
  )
}

export default MemberCard