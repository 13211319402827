// CUR COMPONENT
import React from 'react'
import { Link } from 'react-router-dom'
import { memberRoute } from 'routes'

// COMPONENTS
import Member from 'components/Member'



type Props = {
  links: any,
  members: any,
  filter: string
}

const Members:React.FC<Props> = ({ links, members, filter }) => {
  
  const filterFunc = (item) => {
    return item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
  }

  return (<>
    <div className="members-page__list">
      <div className="members">
        {members.filter(filterFunc).map((item, i) => (
          <Link to={memberRoute.get(item.id)} key={i}>
            <Member 
              name={`${item.name}`}
              type={item.check_out ? 'error' : item.check_in ? 'success' : ''}
            />
          </Link>
        ))}
      </div>
    </div>
  </>)
}

export default Members