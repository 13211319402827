// CUR COMPONENT
import React from 'react'
import './style.scss'

// ICONS
import { ReactComponent as CloseIcon } from './CloseIcon.svg'



type NewCardProps = {
  title: string,
  type: string,
  closeCard(): void,
  className?: string
}

const NewCard:React.FC<NewCardProps> = ({ children, type, title, closeCard, className }) => {
  const classes = ['card']
  if (type) classes.push(`card_${type}`)
  if (className) classes.push(className)

  return (
    <div className="centered_f">
      <div className={classes.join(" ")}>
        <h3 className="card__title">{ title }</h3>
        <div className="card__message">{ children }</div>
        <div className="card__close" onClick={closeCard}>
          <CloseIcon />
        </div>
      </div>
    </div>
  )
}

export default NewCard