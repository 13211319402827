// CUR COMPONENT
import React, { useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { TAuth } from 'types/auth'
import { checkAuth } from 'fetch'
import { loginUser, logoutUser } from 'state/actions/auth'
import { homeRoute, scannerRoute, membersRoute, memberRoute, authRoute, logoutRoute } from 'routes'

// PAGES
import AUTH from 'pages/AUTH'
import MAIN from 'pages/MAIN'
import MEMBER from 'pages/MEMBER'
import MEMBERS from 'pages/MEMBERS'
import SCANNER from 'pages/SCANNER'
import LOGOUT from 'pages/LOGOUT'
import ANY from './ANY'



type Props = {
  auth: TAuth,
  loginUser(user: TAuth): void,
  logoutUser(): void
}

const APP:React.FC<Props> = ({ auth, loginUser, logoutUser }) => {
  useEffect(() => {
    checkAuth()
    .catch(() => {
      logoutUser()
    })
  }, [])

  return auth ? (
    <>
      <Switch>
        <Route exact path={homeRoute.path}>
          <MAIN />
        </Route>

        <Route exact path={membersRoute.path}>
          <MEMBERS />
        </Route>

        <Route exact path={memberRoute.path}>
          <MEMBER />
        </Route>
        
        <Route exact path={scannerRoute.path}>
          <SCANNER />
        </Route>

        <Route exact path={logoutRoute.path}>
          <LOGOUT />
        </Route>

        <Route exact path={authRoute.path}>
          <Redirect to={homeRoute.get()} />
        </Route>

        <Route path="*">
          <ANY />
        </Route>
      </Switch>
    </>
  ) : (
    <>
      <Switch>
        <Route exact path={authRoute.path}>
          <AUTH />
        </Route>

        <Route path="*">
          <Redirect to={authRoute.get()} />
        </Route>
      </Switch>
    </>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
const mapDispatchToProps = (dispatch) => ({
  loginUser: (user) => dispatch(loginUser(user)),
  logoutUser: () => dispatch(logoutUser())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APP)